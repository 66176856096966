import * as React from 'react'
import { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'

import ArrowIcon from '../assets/arrow.inline.svg'
import CopyIcon from '../assets/copy.inline.svg'

import Layout from '../components/layout'
import Actions from '../components/actions'

import Grid from '../components/partials/grid'
import Card from '../components/partials/card'
import ExternalLink from '../components/partials/external'

const ContactPage = ({ data }) => {
	const contact = data.strapi.contactPage

	const [copyContact, setContact] = useState(false)
	const [copyCareer, setCareer] = useState(false)

	return (
		<Layout page={contact} metadata={contact.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1>
						<LargeEmoji label="Contact Page" emoji="&#x1F3C7;" />
						<span className="block">{contact.headline}</span>
					</h1>
				</section>

				{/* CTA Links Section */}
				<section className="pb-20">
					<div className="grid grid-flow-row sm:grid-flow-col justify-start py-10">
						<div className="grid grid-flow-col justify-start items-center">
							<h3 className="font-medium">Start a Project</h3>

							<span className="h-20 lg:h-40 px-10 lg:px-20 pr-10">
								<ArrowIcon className="relative h-full lg:w-40" />
							</span>
						</div>

						<div className="grid grid-flow-col justify-start items-center">
							<ExternalLink link="mailto:911@oppositehq.com">
								<h3>911@oppositehq.com</h3>
							</ExternalLink>

							<Clipboard
								text="911@oppositehq.com"
								action={setContact}
								variable={copyContact}
							/>
						</div>
					</div>

					<div className="grid grid-flow-row sm:grid-flow-col justify-start py-10">
						<div className="grid grid-flow-col justify-start items-center">
							<h3 className="font-medium">Join Opposite</h3>

							<span className="h-20 lg:h-40 px-10 lg:px-20 pr-10">
								<ArrowIcon className="relative h-full lg:w-40" />
							</span>
						</div>

						<div className="grid grid-flow-col justify-start items-center">
							<ExternalLink link="mailto:careers@oppositehq.com">
								<h3>careers@oppositehq.com</h3>
							</ExternalLink>

							<Clipboard
								text="careers@oppositehq.com"
								action={setCareer}
								variable={copyCareer}
							/>
						</div>
					</div>
				</section>

				{/* Social Links Section */}
				<section className="inline-flex flex-row">
					<ExternalLink link="https://instagram.com/opposite.hq">
						<h3 className="inline-flex py-10">Instagram</h3>
					</ExternalLink>

					<h3 className="py-10 px-10"> / </h3>

					<ExternalLink link="https://twitter.com/opposite_hq">
						<h3 className="inline-flex py-10">Twitter</h3>
					</ExternalLink>
				</section>

				{/* Portfolio Cards */}
				<section className="py-40 sm:py-60 md:py-80 lg:py-100 xl:py-120">
					<Grid>
						{contact.projects.map(({ id, project }) => (
							<div key={`project_card_small_${id}`}>
								<Card project={project} />
							</div>
						))}
					</Grid>

					<div className="pt-60 lg:pt-80 xxl:pt-120 text-center">
						<Link className="hover:text-blue underline" to="/portfolio">
							<h2 className="h1">See all projects</h2>
						</Link>
					</div>
				</section>

				{/* Action Cards */}
				<section className="py-40 lg:py-80 xl:py-100 xxl:py-140">
					<Actions />
				</section>
			</div>
		</Layout>
	)
}

ContactPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			contactPage: PropTypes.shape({
				headline: PropTypes.string.isRequired,
				projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

// Large Emoji Span
const LargeEmoji = ({ label, emoji }) => (
	<span
		role="img"
		aria-label={label}
		className="inline-block pb-10 text-56 sm:text-64 md:text-80 lg:text-96 xl:text-96 xxl:text-112"
		style={{ transform: 'rotateY(180deg)' }}
	>
		{emoji}
	</span>
)

LargeEmoji.propTypes = {
	label: PropTypes.string.isRequired,
	emoji: PropTypes.string.isRequired,
}

// Copy to Clipboard
const Clipboard = ({ text, action, variable }) => (
	<CopyToClipboard
		text={text}
		onCopy={() => {
			action(true)
			setTimeout(() => {
				action(false)
			}, 1000)
		}}
	>
		<button
			className="h-30 lg:h-40 pl-10 lg:pl-15 focus:outline-none hover:text-blue"
			style={{ color: variable && '#009C06' }}
		>
			<CopyIcon className="relative h-full lg:w-40 fill-current stroke-current" />
		</button>
	</CopyToClipboard>
)

LargeEmoji.propTypes = {
	label: PropTypes.string.isRequired,
	emoji: PropTypes.string.isRequired,
}

export const query = graphql`
	{
		strapi {
			contactPage {
				headline
				projects {
					id
					project {
						slug
						title
						subtitle
						thumbnail {
							url
							ext
							height
							width
							name
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 360
										height: 270
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default ContactPage
